.hero-background-video {
  position: relative;
  overflow: hidden;
}

.hero-background-video__element {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
